import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import Carousel, { CarouselProps } from 'nuka-carousel';

interface OwnProps extends CarouselProps {
  dataSource: JSX.Element[];
  slidesToShow: number;
  slideToScroll: number;
  hideNavigateButton?: boolean;
  handleClickForward?: () => void;
  handleClickBackward?: () => void;
}

const CarouselWrapper = (props: OwnProps) => {
  const {
    dataSource = [],
    slidesToShow,
    slideToScroll,
    hideNavigateButton,
    handleClickForward = () => {},
    handleClickBackward = () => {},
    // To support other nuka carousel props
    ...otherProps
  } = props;

  return (
    <Carousel
      slidesToShow={slidesToShow}
      slidesToScroll={slideToScroll}
      renderBottomCenterControls={() => null}
      cellSpacing={14}
      renderCenterLeftControls={({ previousSlide, currentSlide }) => {
        if (hideNavigateButton) return null;
        if (dataSource.length <= slidesToShow) return null;
        const disabled = currentSlide === 0;

        if (disabled) return null;

        return (
          <button
            className="w-[30px] h-[30px] center-in-div rounded-full border-none opacity-30 bg-neutral-500"
            onClick={() => {
              handleClickBackward();
              previousSlide();
            }}
          >
            <ChevronLeft size={28} color="white" />
          </button>
        );
      }}
      renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) => {
        if (hideNavigateButton) return null;
        if (dataSource.length <= slidesToShow) return null;
        const disabled = currentSlide + slidesToShow === slideCount;
        if (disabled) return null;

        return (
          <button
            className="w-[30px] h-[30px] center-in-div rounded-full border-none opacity-30 bg-neutral-500"
            onClick={() => {
              handleClickForward();
              nextSlide();
            }}
          >
            <ChevronRight size={28} color="white" />
          </button>
        );
      }}
      {...otherProps}
    >
      {dataSource}
    </Carousel>
  );
};

export default CarouselWrapper;
